import React from "react";
import Layout from "../../components/layout";
import { Link } from "gatsby";

import Container from "../../common/components/container";

const index = () => {
  return (
    <Layout>
      <Container>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Patient Flow
          </h3>
          <div className="mt-3 flex sm:mt-0 sm:ml-4">
            {/* <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Share
          </button> */}
            <Link
              to="/guides/new/"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Create
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default index;
